<template>
  <section id="SectionOurReach">
    <div class="head">
      <Wrap>
        <Headline>
          {{ $t('headline') }}
        </Headline>
        <h2 v-html="$t('title')" />
      </Wrap>
    </div>
    <div class="body">
      <Wrap>
        <Row>
          <Column
            v-for="({ label, number, prefix, suffix }) in stats"
            :key="label"
            md="auto">
            <StatNumber
              :name="label"
              :number="number"
              :prefix="prefix"
              :suffix="suffix" />
          </Column>
        </Row>
      </Wrap>
    </div>
  </section>
</template>

<script>
import StatNumber from './StatNumber'
import { getters } from '../utils'

export default {
  components: { StatNumber },
  computed: {
    ...getters('getSnippetValue'),
    stats() {
      const localeStats = this.$ta('stats')

      return this
        .statsValues
        .map((stat, index) => ({
          ...stat,
          ...localeStats[index],
        }))
    },
    statsValues() {
      return [
        { number: 2017 },
        { number: this.getSnippetValue('experts') || 101 },
        { number: this.getSnippetValue('constructedMw') || 131 },
        { number: '2.7+' },
      ]
    },
  },
}
</script>

<style lang="scss">
#SectionOurReach {
  background-color: $black-bis;
  background-image: url('../../assets/ellipses.png');
  background-position: top right;
  background-repeat: no-repeat;
  color: $white;
  display: grid;
  min-height: 100vh;
  padding: 2rem 0;

  > .head {
    align-items: center;
    display: flex;
  }

  > .body {
    align-items: top;
    display: flex;

    .StatNumber {
      .affix.small {
        font-size: $h3;
      }

      .number {
        font-size: $h15;
      }
    }

    > .Wrap > .Row {
      justify-content: space-between;

      > .Column {
        @include md {
          max-width: 25%;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "headline": "Unsere Reichweite",
    "stats": [
      {
        "label": "Beginn"
      },
      {
        "label": "Interne Experten",
        "prefix": ""
      },
      {
        "label": "Gebaut in Polen, Lithauen, UK",
        "suffix": "MW"
      },
      {
        "label": "Pipeline von Projekten in der Entwicklung",
        "suffix": " GW"
      }
    ],
    "title": "Solarmöglichkeiten<br> sind grenzenlos"
  },
  "en": {
    "headline": "Our reach",
    "stats": [
      {
        "label": "Beginning"
      },
      {
        "label": "In-house experts",
        "prefix": ""
      },
      {
        "label": "Constructed in the United Kingdom, Poland, Lithuania",
        "suffix": "MW"
      },
      {
        "label": "Pipeline of projects under development",
        "suffix": " GW"
      }
    ],
    "title": "Solar opportunities<br> are boundless"
  },
  "it": {
    "headline": "",
    "stats": [
      {
        "label": ""
      },
      {
        "label": "",
        "prefix": ""
      },
      {
        "label": "",
        "suffix": ""
      },
      {
        "label": "",
        "suffix": ""
      }
    ],
    "title": ""
  },
  "lt": {
    "headline": "SIG statistika",
    "stats": [
      {
        "label": "SIG pradžia"
      },
      {
        "label": "Mūsų ekspertai",
        "prefix": ""
      },
      {
        "label": "sumontuota Didžiojoje Britanijoje, Lenkijoje ir Lietuvoje",
        "suffix": "MW"
      },
      {
        "label": "Vystomi projektai",
        "suffix": " GW"
      }
    ],
    "title": "Saulės energijos galimybės yra neribotos"
  },
  "pl": {
    "headline": "SIG w liczbach",
    "stats": [
      {
        "label": "początek SIG"
      },
      {
        "label": "Nasi eksperci",
        "prefix": ""
      },
      {
        "label": "zbudowanych w Polsce, na Litwie i w Wielkiej Brytanii",
        "suffix": "MW"
      },
      {
        "label": "Lista projektów w fazie rozwoju",
        "suffix": " GW"
      }
    ],
    "title": "Możliwości, jakie oferuje branża energetyki solarnej są nieograniczone"
  }
}
</i18n>
