import Index from '../views/pages/Index'
import MainLayout from '../views/layouts/Main'
import { defaultLocale } from '../config'
import localeStore from '../store/modules/locale'

const nonDefaultLocales = localeStore.store.state.locales
  .map(locale => locale.value)
  .filter(localeCode => localeCode !== defaultLocale)
  .join('|')

const rootPath = nonDefaultLocales.length
  ? `/:locale(${nonDefaultLocales})?`
  : ''

export default [
  {
    path: '/:locale(it|pl|lt|es)?/landing-landowners',
    name: 'LandingLandowners',
    component: () => import(/* webpackChunkName: 'landing' */ '../views/pages/LandingLandowners'),
    meta: {
      doRedirectLocale: false,
    },
  },
  {
    path: '/:locale(it|pl)?/blogLandowner/:slug',
    name: 'BlogLandownerItem',
    component: () => import(/* webpackChunkName: 'landing' */ '../views/pages/BlogLandownerItem'),
  },
  // {
  //   path: '/:locale(en|es)?/enerfip',
  //   name: 'LandingEnerfip',
  //   component: () => import(/* webpackChunkName: 'landing' */ '../views/pages/LandingEnerfip'),
  //   // meta: {
  //   //   doRedirectLocale: false,
  //   // },
  // },
  // {
  //   path: '/acquiring-rtb-projects',
  //   name: 'LandingRtb',
  //   component: () => import(/* webpackChunkName: 'landing' */ '../views/pages/LandingRtb'),
  // },
  {
    path: '/view-plot',
    name: 'PlotViewer',
    component: () => import(/* webpackChunkName: 'landing' */ '../views/pages/PlotViewer'),
  },
  // {
  //   path: '/blogLandowner/:slug',
  //   name: 'BlogLandownerItem',
  //   component: () => import(/* webpackChunkName: 'landing' */ '../views/pages/BlogLandownerItem'),
  // },
  {
    path: rootPath,
    component: MainLayout,
    children: [
      {
        path: '/:locale(en|es)?/sig-enerfip-crowdfunding-campaign',
        name: 'LandingEnerfip',
        component: () => import(/* webpackChunkName: 'landing' */ '../views/pages/LandingEnerfip'),
        meta: {
          doRedirectLocale: false,
        },
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/AboutUs'),
      },
      {
        path: 'blog',
        name: 'Blog',
        component: () => import(/* webpackChunkName: 'news' */ '../views/pages/Blog'),
      },
      {
        path: 'blog/:slug',
        name: 'BlogItem',
        component: () => import(/* webpackChunkName: 'news' */ '../views/pages/BlogItem'),
      },
      {
        path: 'career',
        name: 'Career',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Career'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Contact'),
      },
      {
        path: 'faq/landowners',
        name: 'FaqLandowners',
        component: () => import(/* webpackChunkName: 'news' */ '../views/pages/FaqLandowners'),
      },
      {
        path: 'key-success-story',
        name: 'KeySuccessStory',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/KeySuccessStory'),
      },
      {
        path: 'news',
        name: 'NewsPress',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/NewsPress'),
      },
      {
        path: 'news/:slug',
        name: 'Post',
        component: () => import(/* webpackChunkName: 'news' */ '../views/pages/Post'),
      },
      {
        path: 'portfolio',
        name: 'Portfolio',
        component: () => import(/* webpackChunkName: 'portfolio' */ '../views/pages/Portfolio'),
      },
      {
        path: '/:locale(it|pl|lt|de|es)?/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: 'policy' */ '../views/pages/PrivacyPolicy'),
      },
      // {
      //   path: '/solutions',
      //   name: 'Solutions',
      //   component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Solutions'),
      // },
      {
        path: 'solutions/:slug',
        name: 'Solution',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/Solution'),
      },
      {
        path: 'public-bond-issue',
        name: 'LandingFinance',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/LandingFinance'),
      },
      {
        path: 'enerfip',
        name: 'LandingEnerfip',
        component: () => import(/* webpackChunkName: 'pages' */ '../views/pages/LandingEnerfip'),
      },
      {
        path: '',
        name: 'Index',
        component: Index,
      },
      /// REDIRECTS
      { path: 'viesas-obligaciju-platinimas', redirect: '/lt/public-bond-issue' },
    ],
  },
  {
    path: '/denied',
    name: 'Error401',
    component: () => import(/* webpackChunkName: 'ext' */ '../views/errors/401.vue'),
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: 'ext' */ '../views/errors/404.vue'),
  },
  {
    path: '*',
    name: 'Error404',
    component: () => import(/* webpackChunkName: 'ext' */ '../views/errors/404.vue'),
  },
]
